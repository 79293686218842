// 1. Import the initial variables
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";

// 2. Set your own initial variables
$teal: #64A6BD;
$red: #AD343E;
$orange: #CB904D;
$purple: #856084;
$charcoal: #2D4654;

// 3. Set the derived variables
$primary: $teal;
$warning: $orange;

// tabs colors
$tabs-link-hover-color: $charcoal;
$tabs-link-hover-border-bottom-color: $charcoal;
$tabs-link-active-color: $teal;
$tabs-link-active-border-bottom-color: $teal;

// 6. Import the rest of Bulma
@import "../node_modules/bulma/bulma";